import React, {useCallback, useState} from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Alert,
    Spinner
} from "react-bootstrap";
import {authenticationService} from './services/AuthenticationService'
import {Navigate, useNavigate} from "react-router-dom"
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const RegisterPage = () => {
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [alert, setAlert] = useState(null)
    const [buttonEnabled, setButtonEnabled] = useState(true)
    const [buttonContents, setButtonContents] = useState((<span>Register</span>))

    const validateForm = () => {
        return (username.length > 0 && password.length > 0 && passwordConfirm === password);
    }

    const onSubmit = useCallback(async (event) => {
        if (event) event.preventDefault();

        makeButtonBusy()
        hideAlert()

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('createuser');

        // MUST prevent default event or fetch() will be canceled
        authenticationService.createUser(username, email, password, token).then(
            user => {
                navigate("/")
            },
            error => {
                setAlert((
                        <Alert variant="danger" key="invalid_usercreate_request">
                            Invalid registration.
                        </Alert>
                    ))
                makeButtonEnabled()
            }
        );
    },[username, email, password, navigate, executeRecaptcha])

    const hideAlert = () => {
        setAlert(null)
    }

    const makeButtonBusy = () => {
        setButtonEnabled(false)
        setButtonContents((<div><Spinner size="sm" as="span"/>Registering...</div>))
    }

    const makeButtonEnabled = () => {
        setButtonContents((<span>Register</span>))
        setButtonEnabled(true)
    }

    if (authenticationService.currentUserValue) {
        return (<Navigate to="/"/>)
    }

    return (
        <Container>
            {alert}
            <Row className="justify-content-md-center">
                <Col sm={{span: 6, offset: 0}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Register</Card.Title>
                            <Form onSubmit={onSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>Username</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control autoFocus
                                                      value={username}
                                                      onChange={e => setUsername(e.target.value)}
                                                      placeholder="Enter username"
                                                      type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>Email</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control value={email}
                                                      onChange={e => setEmail(e.target.value)}
                                                      placeholder="Enter email"
                                                      type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>Password</Form.Label>
                                    <Col sm={10}><Form.Control
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="off"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>Confirm</Form.Label>
                                    <Col sm={10}><Form.Control
                                        value={passwordConfirm}
                                        onChange={e => setPasswordConfirm(e.target.value)}
                                        placeholder="Confirm Password"
                                        type="password"
                                        autoComplete="off"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col  sm={{ span: 10, offset: 2 }}>
                                        <Button disabled={!validateForm() || !buttonEnabled}
                                            type="submit"
                                            variant="primary"
                                        >
                                            {buttonContents}
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterPage;
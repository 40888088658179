import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Register the required components with Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale);

const BarChart = ({ data }) => {
    // Convert the object to an array of [label, value] pairs, then sort by value
    const sortedData = Object.entries(data).sort((a, b) => b[1] - a[1]);

    // Separate the sorted labels and values
    const labels = sortedData.map(item => item[0]);
    const values = sortedData.map(item => item[1]);

    // Prepare the chart data
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: values,
                backgroundColor: 'rgb(255,153,0)',
                borderColor: 'rgb(0,0,0)',
                borderWidth: 1,
            },
        ],
    };

    // Chart options to make the bar chart horizontal
    const options = {
        indexAxis: 'y', // This makes the chart horizontal
        scales: {
            x: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false, // Disable the legend
            },
        },
    };

    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarChart;

import './App.css'
import {Routes, Route, BrowserRouter as Router, Navigate} from 'react-router-dom'
import {history} from './history'
import LoginPage from './LoginPage'
import CapturePage from "./CapturePage"
import RegisterPage from './RegisterPage'
import PrivateRoute from "./PrivateRoute"
import NotFound from "./NotFound"
import DefaultNavbar from "./DefaultNavbar"
import ProfilePage from "./ProfilePage"
import ScoreboardPage from "./ScoreboardPage";
import AdminRoute from "./AdminRoute";
import ReportPage from "./ReportPage";
import GamePage from "./GamePage";

const targetDate = new Date('2024-08-09T09:00:00-07:00');

function App() {
    return (
        <>
            <Router history={history}>
                <DefaultNavbar/>
                <Routes>
                    <Route path="/" element={
                        <PrivateRoute path="/">
                            <Navigate to="/profile"/>
                        </PrivateRoute>
                    }/>
                    <Route path="/capture" element={
                        <PrivateRoute path="/capture">
                            <CapturePage/>
                        </PrivateRoute>
                    }/>
                    <Route path="/profile" element={
                        <PrivateRoute path="/profile">
                            <ProfilePage/>
                        </PrivateRoute>
                    }/>
                    <Route path="/game" element={<GamePage targetDate={targetDate} className="Game-Page"/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/scoreboard" element={<ScoreboardPage/>}/>
                    <Route path="/report" element={
                        <AdminRoute path="/report">
                            <ReportPage/>
                        </AdminRoute>
                    }/>
                    <Route path="*" element={
                        <PrivateRoute path="/">
                            <NotFound/>
                        </PrivateRoute>
                    }/>
                </Routes>
            </Router>
            <div id="recaptcha"></div>
        </>
    );
}

export default App;

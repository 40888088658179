import React from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Alert,
    Spinner
} from "react-bootstrap";
import {authenticationService} from './services/AuthenticationService'
import {Navigate, Link} from "react-router-dom"

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cardHidden: false,
            username: "",
            password: "",
            from: "/",
            alert: null,
            buttonEnabled: true,
            buttonContents: (<span>Login</span>)
        };

    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0
    }

    onSubmit(event) {
        // MUST prevent default event or fetch() will be canceled
        event.preventDefault();

        this.hideAlert()
        this.makeButtonBusy()

        authenticationService.login(this.state.username, this.state.password).then(
            user => {
                this.setState({from: this.state.path || "/"})
            },
            error => {
                this.setState({
                    alert: (
                        <Alert variant="danger" key="invalid_login">
                            Invalid login credentials.
                        </Alert>
                    )
                })
                this.makeButtonEnabled()
            }
        )
    }

    hideAlert() {
        this.setState({
            alert: null
        })
    }

    makeButtonBusy() {
        this.setState({buttonEnabled: false, buttonContents: (<div><Spinner size="sm" as="span"/>Logging in...</div>)})
    }

    makeButtonEnabled() {
        this.setState({buttonEnabled: true, buttonContents: (<span>Login</span>)})
    }

    componentDidMount() {
        setTimeout(
            function () {
                this.setState({cardHidden: false})
            }.bind(this),
            700
        );
    }

    render() {
        if (authenticationService.currentUserValue) {
            return (<Navigate to={this.state.from}/>)
        }
        return (
            <Container>
                {this.state.alert}
                <Row className="justify-content-md-center">
                    <Col sm={{span: 6, offset: 0}}>
                        <Card hidden={this.state.hidden}>
                            <Card.Body>
                                <Card.Title>Login</Card.Title>
                                <Form onSubmit={e => this.onSubmit(e)}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={2}>Username</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control autoFocus
                                                value={this.state.username}
                                                onChange={e => this.setState({username: e.target.value})}
                                                placeholder="Enter username"
                                                type="text"/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm={2}>Password</Form.Label>
                                        <Col sm={10}><Form.Control
                                            value={this.state.password}
                                            onChange={e => this.setState({password: e.target.value})}
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="off"/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 8, offset: 2 }}>
                                            <Button disabled={!this.validateForm() || !this.state.buttonEnabled}
                                                    type="submit"
                                                    variant="primary">
                                                {this.state.buttonContents}
                                            </Button>
                                            &nbsp;
                                            <Link to="/register"><Button disabled={!this.state.buttonEnabled}>
                                                Register
                                            </Button></Link>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LoginPage
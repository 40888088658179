import React from 'react';
import { Navigate } from 'react-router-dom';

import { authenticationService } from './services/AuthenticationService';
import NotFound from "./NotFound";

export const AdminRoute = ({ children, path }) => {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate replace to='/login' state={{from: path}}/>
    }

    if (!currentUser["roles"]) {
        return <NotFound/>
    }

    if (currentUser["roles"].indexOf("ADMIN") < 0) {
        return <NotFound/>
    }

    // authorised so return component
    return children;
}

export default AdminRoute;
import React, {useCallback, useState} from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Alert,
    Spinner
} from "react-bootstrap";
import {captureService} from './services/CaptureService'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {authenticationService} from "./services/AuthenticationService";
import {useNavigate} from "react-router-dom";
import Clue from "./component/Clue";

const CapturePage = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const user = authenticationService.useUserState()
    const [flag, setFlag] = useState('')
    const [alert, setAlert] = useState([])
    const [buttonEnabled, setButtonEnabled] = useState(true)
    const [buttonContents, setButtonContents] = useState((<span>Submit</span>))
    const navigate = useNavigate()

    if (user.getUser() === null) {
        navigate("/login")
    }

    const validateForm = () => {
        return (flag.length > 0);
    }

    const onSubmit = useCallback(async event => {
        if (event) event.preventDefault();

        makeButtonBusy()
        hideAlert()

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('capture');
        captureService.capture(flag, token)
            .catch(reason => {
                setAlert([(<Alert key='danger' varient='danger'>
                    {'Capture failed! ' + reason}
                </Alert>)])
                throw new Error(reason)
            })
            .then(
                response => {
                    if (response.success) {
                        let alerts = [(<Alert key='sucess' variant='success'>
                            <p>{response.message}</p>
                            <p>You scored {response.points} points!</p>
                        </Alert>)]
                        if (response.clue) {
                            alerts.push((<Alert key='sucess' variant='success'>
                                <p>You unlocked a new clue!</p>
                                <Clue clue={response.clue}/>
                            </Alert>))
                        }
                        setAlert(alerts)
                    } else {
                        setAlert([(<Alert key='danger' variant='danger'>
                            <p>{response.message}</p>
                        </Alert>)])
                    }
                    makeButtonEnabled()
            },
                error => {
                    setAlert([(<Alert key='danger' variant='danger'>
                        <p>{error.message}</p>
                    </Alert>)])
                    makeButtonEnabled()
                })
    },[flag, executeRecaptcha])

    const hideAlert = () => {
        setAlert(null)
    }

    const makeButtonBusy = () => {
        setButtonEnabled(false)
        setButtonContents((<div><Spinner size="sm" as="span"/>Submitting...</div>))
    }

    const makeButtonEnabled = () => {
        setButtonContents((<span>Submit</span>))
        setButtonEnabled(true)
    }

    return (
        <Container>
            {alert}
            <Row className="justify-content-md-center">
                <Col sm={{span: 6, offset: 0}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Capture Flag</Card.Title>
                            <Form onSubmit={onSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={2}>Flag</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control autoFocus
                                                      value={flag}
                                                      onChange={e => setFlag(e.target.value)}
                                                      placeholder="Enter flag"
                                                      type="text"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col  sm={{ span: 10, offset: 2 }}>
                                        <Button disabled={!validateForm() || !buttonEnabled}
                                                type="submit"
                                                variant="primary"
                                        >
                                            {buttonContents}
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>)

}

export default CapturePage
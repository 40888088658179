import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';

// Register the required components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data }) => {
    // Convert the object to an array of [label, value] pairs, then sort by value
    const sortedData = data.sort((a, b) => b[1] - a[1]);

    // Separate the sorted labels and values
    const labels = sortedData.map(item => item[0]);
    const values = sortedData.map(item => item[1]);
    const colors = sortedData.map(item => item[2]);

    // Prepare the chart data
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: values,
                backgroundColor: colors,
                hoverOffset: 4,
            },
        ],
    };

    console.log(chartData);

    return (
        <div>
            <Doughnut data={chartData}/>
        </div>
    );
};

export default DoughnutChart;

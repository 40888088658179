import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey="6LckXlMmAAAAADC6PYfkqKXR2X9Rg7tGoAqCm1K6"
        useEnterprise="true"
        container={{element: "recaptcha", parameters: {badge: "bottomright", theme: "dark"}}}
    >
        <React.StrictMode>
            <div data-bs-theme="dark">
                <App />
            </div>
        </React.StrictMode>
    </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

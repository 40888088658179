import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, Card, Col, Collapse, Container, Row, Spinner, Table} from "react-bootstrap";
import {authenticationService} from "./services/AuthenticationService";
import {captureService} from "./services/CaptureService";
import Moment from "react-moment";
import {ArrowRepeat, ExclamationOctagon} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import Clue from "./component/Clue";

const ProfilePage = () => {
    const style = useMemo(() => {return {
        textAlign: 'center'
    }},[])

    const rightAlign = {
        float: 'right',
    }

    const spinner = [(<tr><td colSpan="4" style={style}><Spinner/></td></tr>)]

    const user = authenticationService.useUserState()
    const [alert, setAlert] = useState([])
    const [capturesTableBody, setCaptureTableBody] = useState(spinner)
    const [captures, setCaptures] = useState(null)
    const [score, setScore] = useState((<Spinner size="sm"/>))
    const [expandState, setExpandState] = useState([])
    const navigate = useNavigate()

    if (user.getUser() === null) {
        navigate("/login")
    }

    const handleExpandClick = (rowId) =>
        setExpandState((prev) => {
            return {...prev, [rowId]: !prev[rowId]}
        })

    useEffect(() => {
        let sum = 0
        let rows = []
        if (captures === null) return;
        captures.forEach(x => {
            sum += x.flag.points
            let row = (
                <tr>
                    <td style={{cursor: "pointer"}} onClick={() => handleExpandClick(x.flag.name)}><div>{expandState[x.flag.name] ? "-" : "+"}</div></td>
                    <td>{x.flag.name}</td><td>{x.flag.points}</td><td><Moment>{x.time}</Moment></td>
                </tr>)
            rows.push(row)
            row = (
                <Collapse as="tr" in={expandState[x.flag.name]}><tr>
                    <td colSpan={4} className="hiddenRow">
                            <Card><Collapse as="tr" in={expandState[x.flag.name]}><div>
                                <Card.Body>
                                    <Card.Text>
                                        {expandState[x.flag.name]}{x.flag.description}
                                    </Card.Text>
                                        <>{x.flag.clue ? <Clue clue={x.flag.clue}/> : ""}</>
                                </Card.Body></div>
                            </Collapse></Card>
                    </td>
                </tr></Collapse>

            )
            rows.push(row)
        })
        setCaptureTableBody(rows)
        setScore(sum)
    }, [captures, expandState])

    const fetchCaptures = useCallback(() => {
        captureService.getCaptures()
            .catch()
            .then(response => {
                setCaptures(response)
            },reason => {
                setAlert((<Alert key='danger' varient='danger'>
                    {'Could not retrieve your flags! ' + reason}
                </Alert>))
                setCaptureTableBody([(<tr><td colSpan="4" style={style}><ExclamationOctagon/></td></tr>)])
                setScore((<ExclamationOctagon/>))
            })
    },[style])

    const refresh = (e) => {
        setCaptureTableBody(spinner)
        setScore((<Spinner size="sm"/>))
        fetchCaptures()
    }

    useEffect(e => {
        fetchCaptures()
    },[fetchCaptures])

    return (
        <Container>
            {alert}
            <Row className="justify-content-md-center">
                <Col sm={{span: 10, offset: 0}}>
                    <Card>
                       <Card.Body>
                           <Button style={rightAlign} onClick={refresh}><ArrowRepeat/></Button>
                           <b>Display Name: </b>{user.getDisplayName()}<br/>
                           <b>Username: </b>{user.getUser().username}<br/>
                           <b>Score: </b>{score}<br/>
                       </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Table hover variant="dark">
                                <thead><tr>
                                    <th style={{width: 30}}></th>
                                    <th>Flag Name</th>
                                    <th>Points</th>
                                    <th>Time</th>
                                </tr></thead>
                                <tbody>
                                    {capturesTableBody}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>)

}

export default ProfilePage
import {Card, Image} from "react-bootstrap";

const Clue = ({clue}) => {

    let arr = []
    if (clue.text) {
        arr.push((<p>{clue.text}</p>))
    }

    if (clue.url) {
        arr.push((<a href={clue.url}>{clue.url}</a>))
    }

    if (clue.image) {
        arr.push((<Image src={clue.image}/>))
    }

    return (<Card>
        <Card.Body>
            <Card.Title>Clue</Card.Title>
            <Card.Text>{arr}</Card.Text>
        </Card.Body>
    </Card>)
}
export default Clue;
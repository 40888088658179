import React, { useState, useEffect } from 'react';
import {Container} from "react-bootstrap";

function GamePage({ targetDate }) {
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(targetDate));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining(targetDate));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetDate]);

    function calculateTimeRemaining(targetDate) {
        const now = new Date();
        const difference = targetDate - now;

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds };
    }

    return (
        <Container className="countdown-container">
        <div className="countdown">
            <p>
                {timeRemaining.hours} hours, {timeRemaining.minutes} minutes, {timeRemaining.seconds} seconds
            </p>
        </div>
        </Container>
    );
}

export default GamePage;
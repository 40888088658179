import {authenticationService} from "./AuthenticationService";

const getReport = () => {
    let headers = {...authenticationService.authHeader(), 'Content-Type': 'application/json'}
    const requestOptions = {
        method: 'GET',
        headers
    }

    return fetch(`/api/report`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                if (response.status === 403) authenticationService.logout()
                throw new Error(response.statusText + " (" + response.status + ")")
            }
        })
}

export const reportService = {
    getReport
}
import React from 'react';
import { Navigate } from 'react-router-dom';

import { authenticationService } from './services/AuthenticationService';

export const PrivateRoute = ({ children, path }) => {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate replace to='/login' state={{from: path}}/>
    }

    // authorised so return component
    return children;
}

export default PrivateRoute;
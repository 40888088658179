import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    Spinner,
    Button
} from "react-bootstrap";
import {ArrowRepeat, ExclamationOctagon} from 'react-bootstrap-icons'

import styled from 'styled-components'

import {reportService} from "./services/ReportService";
import BarChart from "./component/BarChart";
import Table from "./component/Table";
import DoughnutChart from "./component/DoughnutChart";

const TableStyles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const FlagTotalsChartStyles = styled.div`
    width: 300px;
`

const ReportPage = () => {
    const playerFlagsTableColumns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'displayName',
            },
            {
                Header: 'Score',
                accessor: 'score',
            },
            {
                Header: 'Flags',
                accessor: 'flags',
            }],
        []
    );

    const centerAlign = useMemo(() => {
        return {
            textAlign: 'center'}
    },[])

    const rightAlign = {
        float: 'right',
    }

    const spinner = [(<tr><td colSpan="3" style={centerAlign}><Spinner/></td></tr>)]

    const [alert, setAlert] = useState(null)
    const [report, setReport] = useState(spinner)

    const fetchReport = useCallback(() => {
        reportService.getReport()
            .then(response => {
                const totalFlagsFound = Object.values(response["flagTotals"]).length
                const totalData = [
                    ["Not Found", response["totalFlags"] - totalFlagsFound, "rgb(96,96,96)"],
                    ["Found", totalFlagsFound, "rgb(255,153,0)"],
                ];
                setReport([
                    (<BarChart data={response["flagTotals"]}/>),
                    (<FlagTotalsChartStyles><DoughnutChart data={totalData}/></FlagTotalsChartStyles>),
                    (<Table data={response["userFlags"]} columns={playerFlagsTableColumns}/>),
                ])
            },reason => {
                setAlert((<Alert key='danger' varient='danger'>
                    {'Could not retrieve the report! ' + reason}
                </Alert>))
                setReport([(<tr><td colSpan="3" style={centerAlign}><ExclamationOctagon/></td></tr>)])
            })
    }, [centerAlign])

    const refresh = (e) => {
        setAlert(null)
        setReport(spinner)
        fetchReport()
    }

    useEffect(e => {
        fetchReport()
    }, [fetchReport])

    return (
        <Container>
            {alert}
            <Row className="justify-content-md-center">
                <Col sm={{span: 10, offset: 0}}>
                    <Card>
                        <Card.Body>
                            <Button style={rightAlign} onClick={refresh}><ArrowRepeat/></Button>
                            {report}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ReportPage
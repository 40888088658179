import {Container, Dropdown, DropdownButton, Nav, Navbar} from "react-bootstrap";
import {authenticationService} from './services/AuthenticationService'
import {useNavigate} from "react-router-dom";

function DefaultNavbar() {

    const {getDisplayName, isAdmin} = authenticationService.useUserState()
    const navigate = useNavigate()

    const doLogout = () => {
        authenticationService.logout()
        navigate("/login")
    }

    return (
        <Navbar bg="dark" varient="dark">
            <Container>
                <Navbar.Brand>
                    <h1>Project Polybius</h1>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="me-auto">
                        <Nav.Link href="https://ctf.projectpolybius.com" target="_blank">Game</Nav.Link>
                        <Nav.Link onClick={() => navigate("/capture")} hidden={getDisplayName() == null}>Capture</Nav.Link>
                        <Nav.Link onClick={() => navigate("/profile")} hidden={getDisplayName() == null}>Profile</Nav.Link>
                        <Nav.Link onClick={() => navigate("/scoreboard")}>Scoreboard</Nav.Link>
                        <Nav.Link onClick={() => navigate("/login")} hidden={getDisplayName() != null}>Login</Nav.Link>
                        <Nav.Link onClick={() => navigate("/register")} hidden={getDisplayName() != null}>Register</Nav.Link>
                        <Nav.Link onClick={() => navigate("/report")} hidden={!isAdmin()}>Report</Nav.Link>
                        <Nav.Link href="https://discord.gg/bwRRvUYDmh" target="_blank">Discord</Nav.Link>
                    </Nav>
                    <Navbar.Text>
                        <DropdownButton hidden={getDisplayName() == null} title={getDisplayName() || ""}>
                            <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
                        </DropdownButton>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default DefaultNavbar
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Container,
    Row,
    Col,
    Card,
    Alert,
    Spinner,
    Table,
    Button
} from "react-bootstrap";
import {ArrowRepeat, ExclamationOctagon} from 'react-bootstrap-icons'
import {scoreService} from './services/ScoreService'
import Moment from "react-moment";


const ScoreboardPage = () => {
    const centerAlign = useMemo(() => {
        return {
            textAlign: 'center'}
    },[])

    const rightAlign = {
        float: 'right',
    }

    const spinner = [(<tr><td colSpan="3" style={centerAlign}><Spinner/></td></tr>)]

    const [alert, setAlert] = useState(null)
    const [scores, setScores] = useState(spinner)

    const fetchScores = useCallback(() => {
        scoreService.getScores()
            .then(response => {
                let rows = []
                response.forEach(x => {
                    let row = (<tr><td>{x.displayName}</td><td>{x.points}</td><td><Moment>{x.lastTime}</Moment></td></tr>)
                    rows.push(row)
                })
                setScores(rows)
            },reason => {
                setAlert((<Alert key='danger' varient='danger'>
                    {'Could not retrieve the scores! ' + reason}
                </Alert>))
                setScores([(<tr><td colSpan="3" style={centerAlign}><ExclamationOctagon/></td></tr>)])
            })
    }, [centerAlign])

    const refresh = (e) => {
        setAlert(null)
        setScores(spinner)
        fetchScores()
    }

    useEffect(e => {
        fetchScores()
    }, [fetchScores])

    return (
        <Container>
            {alert}
            <Row className="justify-content-md-center">
                <Col sm={{span: 10, offset: 0}}>
                    <Card>
                        <Card.Body>
                            <Button style={rightAlign} onClick={refresh}><ArrowRepeat/></Button>
                            <Table striped hover variant="dark">
                                <thead><tr>
                                    <th>Name</th>
                                    <th>Score</th>
                                    <th>Last Time</th>
                                </tr></thead>
                                <tbody>
                                {scores}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default ScoreboardPage
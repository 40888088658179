import {BehaviorSubject} from 'rxjs';
import {useEffect, useState} from "react";

const currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('polybius_user')));

const useUserState = () => {
    const [user, setUser] = useState({})

    useEffect(() => {
        const subscription = currentUserSubject.subscribe((u) => {
            setUser(u);
        });

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, []);

    function getUser() {
        return user;
    }

    function getDisplayName() {
        return user != null ? user.displayName : null
    }

    function isAdmin() {
        return user != null && user["roles"] && user["roles"].indexOf("ADMIN") > -1;
    }

    return {getUser, getDisplayName, isAdmin}
}

const login = (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, password})
    };

    return fetch(`/api/login`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusMessage + " (" + response.statusCode + ")")
            }
        })
        .then(user => {
            sessionStorage.setItem("polybius_user", JSON.stringify(user));
            currentUserSubject.next(user);
        });

}

const logout = () => {
    // remove user from local storage to log user out
    sessionStorage.removeItem('polybius_user');
    currentUserSubject.next(null);
}

const createUser = (username, email, password, recaptchaToken) => {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({username, email, password, recaptchaToken})
    };

    return fetch(`/api/createUser`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusText + " (" + response.status + ")")
            }
        })
        .then(user => {
            sessionStorage.setItem("polybius_user", JSON.stringify(user));
            currentUserSubject.next(user);
        });

}

const authHeader = () => {
    if (currentUserSubject.value && currentUserSubject.value.token) {
        return {'Authorization': `Bearer ${currentUserSubject.value.token}`};
    }
    return {};
}

export const authenticationService = {
    login,
    logout,
    createUser,
    authHeader,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    useUserState
};

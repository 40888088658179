import {authenticationService} from './AuthenticationService'

const capture = (flag, recaptchaToken) => {

    let headers = {...authenticationService.authHeader(), 'Content-Type': 'application/json'}
    const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify({flag, recaptchaToken})
    };

    return fetch(`/api/capture`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                if (response.status === 403) authenticationService.logout()
                throw new Error(response.statusText + " (" + response.status + ")")
            }
        })
        .then(flagResponse => {
            return flagResponse
        });

}

const getCaptures = () => {
    let headers = authenticationService.authHeader()
    const requestOptions = {
        method: 'GET',
        headers
    };

    return fetch(`/api/getcaptures`, requestOptions)
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                if (response.status === 403) authenticationService.logout()
                throw new Error(response.statusText + " (" + response.status + ")")
            }
        })
}

export const captureService = {
    capture,
    getCaptures
}